import React from 'react';


export const Loader = ({loading, size="", transparent=false}) => {

    return (
        <div id={"preloader" + (transparent ? "-tp" : "")} style={{position: 'absolute', zIndex:"9", display: loading ? "block" : "none"}}>
            <div id={"status" + (size === "xs" ? "-xs" : "")} style={{display: loading ? "block" : "none"}}>
            <div className={"spinner-chase" + (size === "xs" ? "-xs" : "")}>
                <div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div>
            </div>
            </div>
        </div>
    )

}